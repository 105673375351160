import React, { useState, FormEvent } from 'react';
import { CustomAxiosRequestConfig, ShieldAxiosClient } from '../../services/ShieldAxiosClient';

/**
 * Simple contact form to be used on main landing page.
 */
export const ContactForm: React.FC = () => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const axiosConfig: CustomAxiosRequestConfig = {
      url: '/contact-requests',
      method: 'POST',
      data: {
        firstName,
        lastName,
        email,
        message,
      },
      skipAuth: true,
    };

    try {
      setIsLoading(true);
      const response = await ShieldAxiosClient.request(axiosConfig);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsSuccess(true);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="contact-data"
      data-aos="fade-left"
      data-aos-duration="1000">
      <h2>Get in Touch</h2>

      <div className={`form-wrapper ${isSuccess ? 'hidden' : ''}`}>
        <p>We&apos;d love to hear from you.</p>

        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="form-group">
              <label>First name</label>
              <input
                id="firstName"
                type="text"
                name="firstName"
                className="form-control"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                disabled={isLoading}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="form-group">
              <label>Last name</label>
              <input
                id="lastName"
                type="text"
                name="lastName"
                className="form-control"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                disabled={isLoading}
              />
            </div>
          </div>
          <div className="col-md-12 col-sm-12">
            <div className="form-group">
              <label>Email</label>
              <input
                id="email"
                type="email"
                name="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isLoading}
                required
              />
            </div>
          </div>
          <div className="col-md-12 col-sm-12">
            <div className="form-group">
              <label>Message</label>
              <textarea
                id="message"
                className="form-control"
                name="message"
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                disabled={isLoading}
                required
              />
            </div>
          </div>
        </div>

        <div className="btn-group">
          <button
            type="submit"
            className={`btn-tertiary ${isLoading ? 'loading' : ''}`}
            disabled={isLoading}
          >
            <div className="spinner" />
            <span className="button-text">Contact Now</span>
          </button>
        </div>
      </div>

      {isSuccess && (
        <div className='success-message fade-in'>
          <p>Thank you for reaching out to us. We&apos;ll get back to you soon.</p>
        </div>
      )}

    </form>
  );
};
