
import 'aos/dist/aos.css';
import '../../src/stylesheets/v2/application_v2.scss';

import AOS, { init, refresh } from 'aos';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ContactForm } from '../../src/javascript/components/landing/ContactForm';
import '../vendor/bootstrap.bundle.min.js';
import '../vendor/jquery.js';
import '../vendor/slick.min.js';

const handleScroll = () => {
  const isTransparent = document.querySelector('meta[name="navbar-transparent"]').content === "true";
  if (isTransparent) {
    if ($(window).scrollTop() > 0) {
      $("header").addClass("nav-new");
    } else {
      $("header").removeClass("nav-new");
    }
  } else {
    $("header").addClass("nav-new");
  }
}

const smoothScroll = (target) => {
  $('html, body').animate({
    scrollTop: $(target).offset().top - 50
  }, 300);
}

// On Load
$(() => {
  /* Animation effects as user scrolls down the page. */
  AOS.init({
    once: true,
  });

  // Call onload incase initial scroll position is not 0
  handleScroll();

  $(window).scroll(() => {
    handleScroll();
  });

  // Detect hash change and perform smooth scroll
  $(window).on('hashchange', function () {
    const target = window.location.hash;
    if (target) {
      smoothScroll(target);
    }
  });

  // Smooth scroll on initial page load if there's a hash
  if (window.location.hash) {
    smoothScroll(window.location.hash);
  }

  // Collapse navbar when a link is clicked
  $('.nav-link:not(.dropdown-toggle), .dropdown-item').on('click', function () {
    $('.navbar-collapse').removeClass('show');
    $('.navbar-toggler').addClass('collapsed').attr('aria-expanded', 'false');
  });

  // Setup the React contact form
  const contactFormElement = document.getElementById('contact-form');
  if (contactFormElement) {
    const root = ReactDOM.createRoot(contactFormElement);
    root.render(<ContactForm />);
  }

  $('#countrySelect').on('change', function () {
    const selectedCountry = $(this).val();
    document.cookie = `country_code=${selectedCountry}; path=/; max-age=${60 * 60 * 24 * 365}`;
    window.location.hash = '#home';
    window.location.reload();
  });

  const $slider = $(".key-uses-slider");


  $slider.on('init reInit afterChange', function (_event, slick, currentSlide, nextSlide) {
    // If there are fewer slides than the total number of slides in the slider
    if (slick.slideCount <= slick.options.slidesToShow) {
      $('.slider-buttons').css('display', 'none');
    } else {
      $('.slider-buttons').css('display', 'flex');
    }
  });

  /* Slider on use cases page. */
  /* **** Slider ***** */
  $slider.slick({
    arrows: true,
    loop: false,
    dots: false,
    autoplay: false,
    autoplaySpeed: 1500,
    speed: 1000,
    centerMode: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 447,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  });

  $('.slider-btn-prev').on('click', function () {
    $('.key-uses-slider').slick('slickPrev');
  });

  $('.slider-btn-next').on('click', function () {
    $('.key-uses-slider').slick('slickNext');
  });
});

